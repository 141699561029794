import {
  UserSchema,
  useUserGetMe,
  useUserSignOut,
  useUserSync,
} from "hooks/useUser";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cookie from "cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useNetworkStatus from "hooks/useNetworkStatus";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { authConfig } from "authConfig.js";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { EventType } from "@azure/msal-browser";

export interface IUserContext {
  user: UserSchema | undefined;
  setUser: (user: UserSchema | undefined) => void;
  isSignedIn: boolean;
  isLoading: boolean;
  refetchUser: () => Promise<UserSchema | undefined>;
}

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext({} as IUserContext);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { accounts, instance: msalInstance } = useMsal();
  const { isOnline } = useNetworkStatus();
  const [user, setUser] = useState<UserSchema | undefined>(undefined);
  const [isReadyToFetchUser, setIsReadyToFetchUser] = useState(false);

  const navigate = useNavigate();

  const { mutate: mutateUserSignOut } = useUserSignOut({
    onSuccess: () => {
      toast.success("Signed out successfully.");

      navigate("/user/login");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error signing out user");
    },
  });

  const isAuthenticatedWithAD = useIsAuthenticated();

  const checkIsSignedIn = useCallback(() => {
    if (authConfig.mode === "b2c") {
      if (!isOnline) {
        /**
         * If offline & user is not null, then user is signed in
         */
        return !isNullEmptyOrWhitespace(user?.username);
      }

      if (isAuthenticatedWithAD && !isNullEmptyOrWhitespace(user?.username)) {
        return true;
      }

      return false;
    } else {
      // legacy
      const cookies = cookie.parse(document.cookie);
      const tokenCookieExists = cookies.signedIn === "true";

      return tokenCookieExists;
    }
  }, [isAuthenticatedWithAD, isOnline, user?.username]);

  const [isSignedIn, setIsSignedIn] = useState<boolean>(checkIsSignedIn());

  const {
    error: errorUser,
    data: userData,
    isLoading: isLoadingUser,
    fetchData: refetchUser,
  } = useUserGetMe({
    enabled: authConfig.mode === "b2c" ? isReadyToFetchUser : isSignedIn,
    // onSuccess: (response, responseBody) => {
    //   console.log("Success getting user data");
    // },
    onError: (errMessage) => {
      toast.error(`${errMessage}. Signing out`, {
        onClose: () => {
          // If an error occurs, sign out the user
          mutateUserSignOut();
        },
        onClick: () => {
          mutateUserSignOut();
        },
      });

      // If an error occurs, sign out the user
      // mutateUserSignOut();
    },
  });

  const { mutate: mutateUserSync } = useUserSync({
    // No need to show the success message
    onSuccess: (response, responseBody) => {
      // Fetch the updated user data from the DB and set it in the context
      setIsReadyToFetchUser(true);
    },
    onError: (errMessage) => {
      // Redirect to signup page if user is not found
      if (errMessage?.includes("PC1009: User not found. Signup required.")) {
        navigate("/user/signup");
      } else {
        console.error("Error syncing user", errMessage);
        toast.error(errMessage ?? "Error syncing user.");
      }
    },
  });

  useEffect(() => {
    let callbackId: string | null = null;
    if (authConfig.mode === "b2c") {
      callbackId = msalInstance.addEventCallback(
        (event: { eventType: EventType; error: { errorMessage: string } }) => {
          if (
            event.eventType === EventType.LOGIN_FAILURE ||
            event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
            event.eventType === EventType.SSO_SILENT_FAILURE
          ) {
            if (event.error.errorMessage.startsWith("AADB2C99002")) {
              // User does not exist on B2C directory
              // however they are signed in with their Microsoft/Organizational account.
              // The user has reached this point because they have not been added
              // to the B2C directory and are NOT using a SIGN-UP_SIGN-IN policy.
              navigate("/user/login");

              toast.error(
                "User does not exist on B2C directory. Please ask an admin to add you to the directory and try again. Alternatively, sign in with another account."
              );
            }
          }
        }
      );
    }

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSignedIn = checkIsSignedIn();
    setIsSignedIn(isSignedIn);
  }, [checkIsSignedIn]);

  useEffect(() => {
    if (isSignedIn && user?.permissionGroupId === -1) {
      console.error(user, user?.permissionGroupId);
      toast.warn(
        "You have limited permissions. If this is your first time logging in, please contact an administrator to request the necessary permissions.",
        {
          toastId: "user-permission-warning",
          autoClose: false,
        }
      );
    }
  }, [isSignedIn, user]);

  useEffect(() => {
    if (authConfig.mode !== "b2c") {
      return;
    }

    if (!navigator.onLine) {
      // deliberately not using isOnline here to avoid re-rendering
      setIsReadyToFetchUser(true);
      return;
    }

    if (accounts.length > 0) {
      // Assuming the first account is the one you're interested in
      // const userId = accounts[0].localAccountId;

      // Calling mutateUser will update/insert the user in the DB from the Azure AD data
      mutateUserSync({
        email: accounts[0].username,
        displayName: accounts[0].name,
        // customerName: undefined,
        // permissionLevel: undefined,
        // permissionGroup: undefined,
      });
    } else {
      setUser(undefined);
    }
  }, [accounts, mutateUserSync, setUser]);

  useEffect(() => {
    if (errorUser) {
      console.error(errorUser);
    }
  }, [errorUser]);

  useEffect(() => {
    setUser(userData);
  }, [setUser, userData]);

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      isSignedIn,
      isLoading: isLoadingUser,
      refetchUser,
    }),
    [user, setUser, isSignedIn, isLoadingUser, refetchUser]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
